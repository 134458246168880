import React from 'react';

import GlossaryList from '../../components/glossary-list';
import { Layout } from '../../layout';
import { Section as StyledSection } from '../../components/styled';
import glossaryTerms from './glossaryTerms.json';

export default function Glossary() {
  return (
    <Layout title="Glossary">
      <StyledSection
        maxWidth={1360}
        paddingTablet="40px 30px 100px"
        paddingDesktop="60px 116px 30px"
      >
        <h1>Glossary/ Terminology</h1>

        <GlossaryList glossaryTerms={glossaryTerms} />
      </StyledSection>
    </Layout>
  );
}
